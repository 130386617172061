
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.js';
import Hover from 'wavesurfer.js/dist/plugins/hover.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.js'

import knockout from 'knockout';


console.log("loading audio-library bundle");

window.ko=knockout;
 
frappe.provide("galcom.al");
window.galcom.al = {
    wavesurfer: WaveSurfer,
    wsPlugins:{
        RegionsPlugin: RegionsPlugin,
        Hover:Hover,
        TimelinePlugin:TimelinePlugin,
    },
}